<!-- Container Principal -->
<div class="flex flex-col custom-container -m-6">

  <!-- Cabeçalho do Orçamento -->
  <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
    <div class="text-lg font-medium">Orçamento de Venda</div>
    <button mat-icon-button (click)="cancel()">
      <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
    </button>
  </div>

  <!-- Indicador de Carregamento -->
  <div *ngIf="loading" class="loading-overlay">
    <mat-spinner diameter="50" color="accent"></mat-spinner>
  </div>

  <!-- Formulário Principal -->
  <div class="flex flex-col flex-auto p-6 sm:p-8">

    <!-- Cabeçalho do Formulário -->
    <div class="header">
      <div class="title-cabecalho">Cabeçalho</div>
    </div>

    <!-- Linha 1: Busca Cliente, Cod Cliente e CPF/CNPJ -->
    <div class="linha">
      <mat-form-field class="meio-width" appearance="outline">
        <mat-icon matPrefix svgIcon="heroicons_solid:search"></mat-icon>
        <mat-label>Busca Cliente</mat-label>
        <input matInput [formControl]="buscaClienteControl" [matAutocomplete]="autoCliente">
        <mat-autocomplete #autoCliente="matAutocomplete" (optionSelected)="selecionarCliente($event)" [displayWith]="displayCliente">
          <mat-option *ngFor="let cliente of clientesFiltrados | async" [value]="cliente">
            {{ cliente.CardCode + ' - ' + cliente.Nome_do_PN }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Cod Cliente</mat-label>
        <input matInput [formControl]="codClienteControl" readonly>
      </mat-form-field>

      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>CPF/CNPJ</mat-label>
        <input matInput [formControl]="cpfControl" readonly>
      </mat-form-field>
    </div>

    <!-- Linha 2: Inscrição Estadual, Fazenda, Telefone, Endereço -->
    <div class="linha">
      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Inscrição Estadual</mat-label>
        <input matInput [formControl]="inscricaoControl" readonly>
      </mat-form-field>

      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Fazenda</mat-label>
        <input matInput [formControl]="fazendaControl" readonly>
      </mat-form-field>

      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Telefone</mat-label>
        <input matInput [formControl]="telefoneControl" readonly>
      </mat-form-field>

      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Endereço</mat-label>
        <input matInput [formControl]="enderecoControl" readonly>
      </mat-form-field>
    </div>

    <!-- Linha 3: Data de Entrega, Frete, Cd. Entrega -->
    <div class="linha">
      <mat-form-field class="terco-width" appearance="outline">
        <mat-label>Data de Entrega</mat-label>
        <input matInput [formControl]="dataVencimentoControl" [matDatepicker]="pickerScheduled"/>
        <mat-datepicker-toggle matSuffix [for]="pickerScheduled"></mat-datepicker-toggle>
        <mat-datepicker #pickerScheduled></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="terco-width" appearance="outline">
        <mat-label>Frete</mat-label>
        <input matInput [formControl]="freteControl" [matAutocomplete]="autoFrete">
        <mat-autocomplete #autoFrete="matAutocomplete" (optionSelected)="selecionarFrete($event)">
          <mat-option *ngFor="let frete of fretesFiltrados" [value]="frete.DESCRICAO">
            {{ frete.DESCRICAO }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="dois-tercos-width" appearance="outline">
        <mat-label>Cd.Entrega</mat-label>
        <input matInput [formControl]="CdEntregaControl" [matAutocomplete]="autoCdEntrega">
        <mat-autocomplete #autoCdEntrega="matAutocomplete" (optionSelected)="selecionarCdEntrega($event)">
          <mat-option *ngFor="let cdEntrega of filteredCdEntrega" [value]="cdEntrega">
            {{ cdEntrega.BPLName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <!-- Linha 4: Vendedor, Condições de Pagamento, Meio de Pagamento e Observações -->
    <div class="linha">
      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Vendedor</mat-label>
        <input matInput [formControl]="vendedorControl" [matAutocomplete]="autoVendedor">
        <mat-autocomplete #autoVendedor="matAutocomplete" (optionSelected)="selecionarVendedor($event)">
          <mat-option *ngFor="let vendedor of filteredVendedores" [value]="vendedor">
            {{ vendedor.SlpName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Condições de Pagamento</mat-label>
        <input matInput [formControl]="condicaoPagamentoControl" [matAutocomplete]="autocondicaoPagamento">
        <mat-autocomplete #autocondicaoPagamento="matAutocomplete" (optionSelected)="selecionarCondicaoPagamento($event)">
          <mat-option *ngFor="let condicao of condicoesPagamentoFiltradas" [value]="condicao.PymntGroup">
            {{ condicao.PymntGroup }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="meio-width" appearance="outline">
        <mat-label>Meio de Pagamento</mat-label>
        <input matInput [formControl]="formaPagamentoControl" [matAutocomplete]="autoformaPagamento">
        <mat-autocomplete #autoformaPagamento="matAutocomplete">
          <mat-option *ngFor="let forma of formasPagamentoFiltradas" [value]="forma.FPAGAMENTO">
            {{ forma.FPAGAMENTO }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Observações</mat-label>
        <input matInput [formControl]="observacoescadControl">
      </mat-form-field>
    </div>

    <!-- Seção de Manejo Biológico -->
    <div class="header">
      <div class="title-cabecalho">Manejo Biológico</div>
    </div>
    <div class="linha">
      <mat-form-field class="mat-form-field-faixa-compra" appearance="outline">
        <mat-label>Faixa de Compra Mínima</mat-label>
        <mat-select [formControl]="faixaCompraControl" placeholder="Selecione a faixa">
          <mat-option *ngFor="let faixa of faixasDeCompra" [value]="faixa">
            {{ faixa.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="mat-form-field-qualyfarm" appearance="outline">
        <mat-label>Sanitização</mat-label>
        <mat-select [formControl]="sanatizacaoProdutoControl" placeholder="Selecione a qualidade">
          <mat-option *ngFor="let tipo of tiposSanitizacao" [value]="tipo">
            {{ tipo }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      

    </div>

    <!-- Seção de Itens -->
    <div class="header">
      <div class="title-cabecalho">Itens</div>
    </div>

    <div class="itens-scroll" #itensScroll>
      <div class="itens">

        <!-- Linha de cabeçalho com títulos das colunas -->
        <div class="linha header">
          <div class="col col-nitem">NItem</div>
          <div class="col col-descricao">Descrição do Produto</div>
          <div class="col col-quantidadeAplicacoes">Nº Aplic.</div>
          <div class="col col-doseBiologica">Dose Biológica (L)</div>
          <div class="col col-numeroHectares">Nº Ha</div>          
          <div class="col col-nrKit">Nr Kit</div>          
          <div class="col col-custoKgL">Custo Kg/L</div>
          <div class="col col-custoTotalHaBiologico">Custo Total/Ha Biológico</div>
          <div class="col col-precoUnitario">Preço Unitário</div>
          <div class="col col-quantidade">Quantidade</div>
          <div class="col col-desconto">Desconto</div>
          <div class="col col-codVenda">Cód. Venda</div>
          <div class="col col-aplicacao">Aplicação</div>
          <div class="col col-cultura">Cultura</div>
          <div class="col col-dataEntrega">Data de Entrega</div>
          <div class="col col-numeroOC">Número OC</div>
          <div class="col col-valorTotal">Valor Total</div>
          <div class="col col-acao"></div>
        </div>
        

        <ng-container *ngFor="let item of dadosTabela">
          <div class="linha header preenchida" *ngIf="item.tipoGrupo !== 'kitComplementar'">
        
            <div class="col col-nitem position-relative">
              <mat-form-field class="mat-form-field-nitem" appearance="outline">
                <mat-label>NItem</mat-label>
                <input
                  type="text"
                  matInput
                  [value]="item.nItem"
                  readonly
                >
              </mat-form-field>
              <span *ngIf="item.tipoGrupo === 'kitComplementar'" class="tag-kit">Kit Complementar</span>
            </div>
        
            <div class="col col-descricao">
              <mat-form-field class="mat-form-field-descricao" appearance="outline">
                <mat-label>Descrição do Produto</mat-label>
                <input
                  type="text"
                  matInput
                  [value]="item.descricaoProduto"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-quantidadeAplicacoes">
              <mat-form-field class="mat-form-field-quantidadeAplicacoes" appearance="outline">
                <mat-label>Quantidade de Aplicações</mat-label>
                <input
                  type="number"
                  matInput
                  [value]="item.quantidadeAplicacoes"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-doseBiologica">
              <mat-form-field class="mat-form-field-doseBiologica" appearance="outline">
                <mat-label>Dose Biológica (L)</mat-label>
                <input
                  type="number"
                  matInput
                  [value]="item.doseBiologica"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-numeroHectares">
              <mat-form-field class="mat-form-field-numeroHectares" appearance="outline">
                <mat-label>Número de Hectares (Ha)</mat-label>
                <input
                  type="number"
                  matInput
                  [value]="item.numeroHectares"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-nrKit">
              <mat-form-field class="mat-form-field-nrKit" appearance="outline">
                <mat-label>Nr Kit</mat-label>
                <input
                  type="number"
                  matInput
                  [value]="item.nrKit"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-custoKgL">
              <mat-form-field class="mat-form-field-custoKgL" appearance="outline">
                <mat-label>Custo Kg/L</mat-label>
                <input
                  type="text"
                  matInput
                  [value]="item.custoKgL"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-custoTotalHaBiologico">
              <mat-form-field class="mat-form-field-custoTotalHaBiologico" appearance="outline">
                <mat-label>Custo Total/Ha Biológico</mat-label>
                <input
                  type="text"
                  matInput
                  [value]="item.custoTotalHaBiologico"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-precoUnitario">
              <mat-form-field class="mat-form-field-precoUnitario" appearance="outline">
                <mat-label>Preço Unitário</mat-label>
                <input
                  type="text"
                  matInput
                  [value]="item.precoUnitario"
                  (ngModelChange)="validarERecalcularValorTotal(item)"
                >
              </mat-form-field>
            </div>
        
            <div class="col col-quantidade">
              <mat-form-field class="mat-form-field-quantidade" appearance="outline">
                <mat-label>Quantidade</mat-label>
                <input
                  type="number"
                  matInput
                  [value]="item.quantidade"
                  (ngModelChange)="recalcularValorTotal(item)"
                >
              </mat-form-field>
            </div>
        
            <div class="col col-desconto">
              <mat-form-field class="mat-form-field-desconto" appearance="outline">
                <mat-label>Desconto</mat-label>
                <input
                  type="number"
                  matInput
                  [value]="item.desconto"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-codVenda">
              <mat-form-field class="mat-form-field-codVenda" appearance="outline">
                <mat-label>Cód. Venda</mat-label>
                <mat-select [value]="item.codVenda" disabled>
                  <mat-option value="212 VFU">VFU</mat-option>
                  <mat-option value="222 VDI">VDI</mat-option>
                  <mat-option value="210 BNF">BNF</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        
            <div class="col col-aplicacao">
              <mat-form-field class="mat-form-field-aplicacao" appearance="outline">
                <mat-label>Aplicação</mat-label>
                <mat-select [value]="item.aplicacao" disabled>
                  <mat-option value="OnFarm">On Farm</mat-option>
                  <mat-option value="OnDemand">On Demand</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        
            <div class="col col-cultura">
              <mat-form-field class="mat-form-field-cultura" appearance="outline">
                <mat-label>Cultura</mat-label>
                <mat-select [value]="item.cultura" disabled>
                  <mat-option *ngFor="let cultura of filteredCulturas" [value]="cultura.Name">
                    {{ cultura.Name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        
            <div class="col col-dataEntrega">
              <mat-form-field class="mat-form-field-dataEntrega" appearance="outline">
                <mat-label>Data de Entrega</mat-label>
                <input
                  matInput
                  [value]="item.dataEntrega | date"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-numeroOC">
              <mat-form-field class="mat-form-field-numeroOC" appearance="outline">
                <mat-label>Número OC</mat-label>
                <input
                  type="number"
                  matInput
                  [value]="item.numeroOC"
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-valorTotal">
              <mat-form-field class="mat-form-field-valorTotal" appearance="outline">
                <mat-label>Valor Total</mat-label>
                <input
                  type="text"
                  matInput
                  [value]="item.valorTotal "
                  readonly
                >
              </mat-form-field>
            </div>
        
            <div class="col col-acao">
              <button mat-icon-button (click)="removerItem(item)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
        
          </div> 
        </ng-container>
        

        <!-- ADICIONAR ITEM -->

        
        <div class="linha">
          <div class="col col-nitem">
            <mat-form-field class="mat-form-field-nitem" appearance="outline">
              <mat-label>NItem</mat-label>
              <input type="text" matInput [formControl]="nItemControl" [matAutocomplete]="autoNItem">
              <mat-autocomplete #autoNItem="matAutocomplete"   class="custom-autocomplete" [panelWidth]="'auto'" (optionSelected)="selecionarProdutoNovoItem($event)">
                <mat-option *ngFor="let item of filteredItems | async" [value]="item.ItemCode">
                  {{ item.ItemCode }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        
          <div class="col col-descricao">
            <mat-form-field class="mat-form-field-descricao" appearance="outline">
              <mat-label>Descrição do Produto</mat-label>
              <input type="text" matInput [formControl]="descricaoProdutoControl" [matAutocomplete]="autoDescricaoProduto">
              <mat-autocomplete #autoDescricaoProduto="matAutocomplete" (optionSelected)="selecionarProdutoNovoItem($event)">
                <mat-option *ngFor="let item of filteredItemDescriptions | async" [value]="item.ItemName">
                  {{ item.ItemName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col col-quantidadeAplicacoes">
            <mat-form-field class="mat-form-field-quantidadeAplicacoes" appearance="outline">
              <mat-label>Quantidade de Aplicações</mat-label>
              <input type="number" matInput [formControl]="quantidadeAplicacoesControl" placeholder="Ex: 10">
            </mat-form-field>
          </div>
        
          <div class="col col-doseBiologica">
            <mat-form-field class="mat-form-field-doseBiologica" appearance="outline">
              <mat-label>Dose Biológica (L)</mat-label>
              <input type="number" matInput [formControl]="doseBiologicaControl" placeholder="Ex: 1.5">
            </mat-form-field>
          </div>
        
          <div class="col col-numeroHectares">
            <mat-form-field class="mat-form-field-numeroHectares" appearance="outline">
              <mat-label>Número de Hectares (Ha)</mat-label>
              <input type="number" matInput [formControl]="numeroHectaresControl"  (ngModelChange)="atualizarCalculos()" placeholder="Ex: 100">
            </mat-form-field>
          </div>

          <div class="col col-nrKit">
            <mat-form-field class="mat-form-field-nrKit" appearance="outline">
              <mat-label>Nr Kit</mat-label>
              <input type="number" matInput [formControl]="nrKitControl" readonly>
            </mat-form-field>
          </div>

          <div class="col col-custoKgL">
            <mat-form-field class="mat-form-field-custoKgL" appearance="outline">
              <mat-label>Custo Kg/L</mat-label>
              <input type="text" matInput [formControl]="custoKgLControl" readonly>
            </mat-form-field>
          </div>
        
          <div class="col col-custoTotalHaBiologico">
            <mat-form-field class="mat-form-field-custoTotalHaBiologico" appearance="outline">
              <mat-label>Custo Total/Ha Biológico</mat-label>
              <input type="text" matInput [formControl]="custoTotalHaBiologicoControl"   (ngModelChange)="atualizarCalculos()" readonly>
            </mat-form-field>
          </div>
        
          <div class="col col-precoUnitario">
            <mat-form-field class="mat-form-field-precoUnitario" appearance="outline">
              <mat-label>Preço Unitário</mat-label>
              <input
                type="text"
                matInput
                [formControl]="precoUnitarioControl"
                (ngModelChange)="recalcularNovoValorTotal()"
              >
            </mat-form-field>
          </div>
          
        
          <div class="col col-quantidade">
            <mat-form-field class="mat-form-field-quantidade" appearance="outline">
              <mat-label>Quantidade</mat-label>
              <input
                type="number"
                matInput
                [formControl]="quantidadeControl"
                (ngModelChange)="recalcularNovoValorTotal()"
              >
            </mat-form-field>
          </div>
          <div class="col col-desconto">
            <mat-form-field class="mat-form-field-desconto" appearance="outline">
              <mat-label>Desconto</mat-label>
              <input type="number" matInput [formControl]="descontoControl">
            </mat-form-field>
          </div>
        
          <div class="col col-codVenda">
            <mat-form-field class="mat-form-field-codVenda" appearance="outline">
              <mat-label>Cód. Venda</mat-label>
              <mat-select [formControl]="codControl" (selectionChange)="gerarCodPedido($event)">
                <mat-option value="212 VFU">VFU</mat-option>
                <mat-option value="222 VDI">VDI</mat-option>
                <mat-option value="210 BNF">BNF</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        
          <div class="col col-aplicacao">
            <mat-form-field class="mat-form-field-aplicacao" appearance="outline">
              <mat-label>Aplicação</mat-label>
              <mat-select [formControl]="aplicacaoControl">
                <mat-option value="OnFarm">On Farm</mat-option>
                <mat-option value="OnDemand">On Demand</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        
          <div class="col col-cultura">
            <mat-form-field class="mat-form-field-cultura" appearance="outline">
              <mat-label>Cultura</mat-label>
              <input type="text" matInput [formControl]="culturaControl" [matAutocomplete]="autoCultura">
              <mat-autocomplete #autoCultura="matAutocomplete">
                <mat-option *ngFor="let cultura of filteredCulturas" [value]="cultura.Name">
                  {{ cultura.Name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        
          <div class="col col-dataEntrega">
            <mat-form-field class="mat-form-field-dataEntrega" appearance="outline">
              <mat-label>Data de Entrega</mat-label>
              <input matInput [formControl]="dataEntregaControl" [matDatepicker]="pickerScheduledE" />
              <mat-datepicker-toggle matSuffix [for]="pickerScheduledE"></mat-datepicker-toggle>
              <mat-datepicker #pickerScheduledE></mat-datepicker>
            </mat-form-field>
          </div>
        
          <div class="col col-numeroOC">
            <mat-form-field class="mat-form-field-numeroOC" appearance="outline">
              <mat-label>Número OC</mat-label>
              <input type="number" matInput [formControl]="numeroOCControl">
            </mat-form-field>
          </div>

          <div class="col col-valorTotal">
            <mat-form-field class="mat-form-field-valorTotal" appearance="outline">
              <mat-label>Valor Total</mat-label>
              <input type="text" matInput [value]="newValorTotalControl.value " readonly>
            </mat-form-field>
          </div>
          
          <div class="col col-acao">
          </div>
        </div>
        
      </div>
    </div>

    <div class="add-item-button">
      <button mat-icon-button color="primary" (click)="adicionarNovoItem()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="totals-section">
      <div>
        <strong>Total (Investimento por Ha):</strong>
        <span>{{ totalInvestimentoPorHa | currency:'BRL' }}</span>
      </div>
      <div>
        <strong>Investimento Total no Manejo:</strong>
        <span>{{ investimentoTotalNoManejo | currency:'BRL' }}</span>
      </div>
    </div>
   
    <div class="header" *ngIf="possuiItensComplementares">
      <div class="title-cabecalho">Itens Complementares</div>
    </div>
    <div class="itens-scroll" *ngIf="possuiItensComplementares" #itensScrollKits>
      <div class="itens">
        <!-- Linha de cabeçalho com títulos das colunas -->
        <div class="linha header">
          <div class="col col-nitem">NItem</div>
          <div class="col col-descricao">Descrição do Produto</div>
          <div class="col col-quantidade">Quantidade</div>
          <div class="col col-precoUnitario">Preço Unitário</div>
          <div class="col col-valorTotal">Valor Total</div>
        </div>
        
        <ng-container *ngFor="let item of dadosTabelaFiltrados">
          <div class="linha header preenchida">
            <div class="col col-nitem">
              <mat-form-field class="mat-form-field-nitem" appearance="outline">
                <input type="text" matInput [value]="item.nItem" readonly>
              </mat-form-field>
            </div>
            <div class="col col-descricao">
              <mat-form-field class="mat-form-field-descricao" appearance="outline">
                <input type="text" matInput [value]="item.descricaoProduto" readonly>
              </mat-form-field>
            </div>
            <div class="col col-quantidade">
              <mat-form-field class="mat-form-field-quantidade" appearance="outline">
                <input type="number" matInput [value]="item.quantidade" readonly>
              </mat-form-field>
            </div>
            <div class="col col-precoUnitario">
              <mat-form-field class="mat-form-field-precoUnitario" appearance="outline">
                <input
                  type="text"
                  matInput
                  [value]="item.precoUnitario"
                  readonly
                >
              </mat-form-field>
            </div>
            <div class="col col-valorTotal">
              <mat-form-field class="mat-form-field-valorTotal" appearance="outline">
                <input
                  type="text"
                  matInput
                  [value]="item.valorTotal "
                  readonly
                >
              </mat-form-field>
            </div>
            
            <div class="col col-acao">
              <button mat-icon-button (click)="removerItem(item)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
        
          </div>
        </ng-container>
      </div>
    </div>
    <div class="totals-section">
      <div>
        <strong> Valor Total:</strong>
        <span>{{ somaValorTotalItens | currency:'BRL' }}</span>
      </div>
    </div>
  </div>




  <div class="send-to-api-button">
    <button mat-raised-button class="custom-save-button" (click)="enviarDadosParaAPI()">Salvar</button>
  </div>
  
</div>
